import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import ElementUI  from './element';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.min.css' 
// import 'swiper/dist/js/swiper.min'
import http from '@/utils/request'
import httpzip from '@/utils/requestZip'
// import VideoPlayer from 'vue-video-player'
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// Vue.use(VideoPlayer)

import { Picker } from 'vant'

Vue.use(Picker)
import 'vant/lib/index.css'

Vue.use(ElementUI)
Vue.config.productionTip = false

// 挂载全局
Vue.prototype.$http = http
Vue.prototype.$httpzip = httpzip
Vue.prototype.$bus = new Vue() 
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
