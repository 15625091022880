<template>
  <div class="theme-star">
    <vueHeader @getClass="getClass"></vueHeader>
    <vueContent></vueContent>
    <vueFooter :className="className"></vueFooter>
  </div>
</template>

<script>
import vueHeader from "./components/vue-header.vue";
import vueContent from "./components/vue-content.vue";
import vueFooter from "./components/vue-footer.vue";
export default {
  name: "app",
  components: {
    vueHeader,
    vueContent,
    vueFooter,
  },
  data(){
    return{
      className:''
    }
  },
  created() {
    if (document.body.offsetWidth > 768) {
      this.resize(1440, 3000);
    } else {
      this.resize(375, 768);
    }

    document
      .getElementsByTagName("body")[0]
      .style.setProperty("--theme-color", "#b20103");
  },
  methods: {
    resize(designWidth, maxWidth) {
      console.log("ssssss");
      var doc = document,
        win = window,
        docEl = doc.documentElement,
        remStyle = document.createElement("style"),
        tid;

      function refreshRem() {
        // var width = window.screen.width;
        var width = document.body.offsetWidth;
        console.log(width, designWidth, "rem");
        maxWidth = maxWidth || 540;
        width > maxWidth && (width = maxWidth);
        var rem = (width * 100) / designWidth;
        console.log(rem, width, designWidth, "rem");
        remStyle.innerHTML = "html{font-size:" + rem + "px;}";
      }

      if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
      } else {
        var wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
      }

      refreshRem();

      win.addEventListener(
        "resize",
        function () {
          clearTimeout(tid);
          tid = setTimeout(refreshRem, 300);
        },
        false
      );

      win.addEventListener(
        "pageshow",
        function (e) {
          if (e.persisted) {
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
          }
        },
        false
      );

      if (doc.readyState === "complete") {
        doc.body.style.fontSize = "16px";
      } else {
        doc.addEventListener(
          "DOMContentLoaded",
          function () {
            doc.body.style.fontSize = "16px";
          },
          false
        );
      }
    },
    getClass(className){
      this.className =  className
    }
  },
};
</script>
<style lang="scss">


// 给文本内容加高度，滚动条
   .quill-editor .ql-container {
    min-height: 220px;
    height: auto !important;
  }

  .ql-container {
    min-height: 230px;
  }


    .ql-snow .ql-tooltip [data-mode="link"]::before {
      content: "请输入链接地址:";
      left: 0;
    }

    .ql-snow .ql-tooltip.ql-editing {
      left: 0 !important;
    }

    .ql-snow .ql-tooltip {
      left: 0 !important;
    }

    .ql-snow .ql-editor {
      // max-height: 300px;
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      border-right: 0px;
      content: "保存";
      padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode="video"]::before {
      content: "请输入视频地址:";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
      content: "14px" !important;
      font-size: 14px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
      content: "10px" !important;
      font-size: 10px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
      content: "12px" !important;
      font-size: 12px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
      content: "16px" !important;
      font-size: 16px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
      content: "18px" !important;
      font-size: 18px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
      content: "20px" !important;
      font-size: 20px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
      content: "30px" !important;
      font-size: 30px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
      content: "32px" !important;
      font-size: 32px;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {
      content: "文本" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: "标题1" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: "标题2" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: "标题3" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: "标题4" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: "标题5" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: "标题6" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before, .ql-snow .ql-picker.ql-font .ql-picker-item::before {
      content: "标准字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
      content: "衬线字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
      content: "等宽字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
      content: "宋体" !important;
      font-family: "SimSun";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
      content: "黑体" !important;
      font-family: "SimHei";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Microsoft-YaHei"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Microsoft-YaHei"]::before {
      content: "微软雅黑" !important;
      font-family: "Microsoft YaHei";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
      content: "楷体" !important;
      font-family: "KaiTi";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
      content: "仿宋" !important;
      font-family: "FangSong";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
      content: "Arial" !important;
      font-family: "Arial";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times-New-Roman"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times-New-Roman"]::before {
      content: "Times New Roman" !important;
      font-family: "Times New Roman";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
      content: "sans-serif" !important;
      font-family: "sans-serif";
    }

    .ql-font-SimSun {
      font-family: "SimSun";
    }

    .ql-font-SimHei {
      font-family: "SimHei";
    }

    .ql-font-Microsoft-YaHei {
      font-family: "Microsoft YaHei";
    }

    .ql-font-KaiTi {
      font-family: "KaiTi";
    }

    .ql-font-FangSong {
      font-family: "FangSong";
    }

    .ql-font-Arial {
      font-family: "Arial";
    }

    .ql-font-Times-New-Roman {
      font-family: "Times New Roman";
    }

    .ql-font-sans-serif {
      font-family: "sans-serif";
    }

    .ql-snow.ql-toolbar .ql-formats .ql-revoke {
      // background-image: url("../../../../assets/images/icons8-rotate-left-18.png");
      width: 20px;
      height: 20px;
      filter: grayscale(100%);
      opacity: 1;
    }

    .ql-snow.ql-toolbar .ql-formats .ql-revoke:hover {
      // background-image: url("../../../../assets/images/icons8-rotate-left-18.png");
      width: 20px;
      height: 20px;
      filter: none;
      opacity: 0.9;
    }

    img {
      // filter: grayscale(100%);
      opacity: 1;
    }

    img:hover {
      filter: none;
      opacity: 0.9;
    }

    /*加上height和滚动属性就可以，滚动条样式是系统默认样式，可能不同*/
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      border-color: #ccc;
      height: 125px;
      overflow: auto;
    }
  

</style>


<style lang='scss'>

@font-face {
  font-family: FangSong;
  src        : url("./assets/font-face/simfang.ttf");
}

@font-face {
  font-family: SimHei;
  src        : url("./assets/font-face/simhei.ttf");
}

@font-face {
  font-family: KaiTi;
  src        : url("./assets/font-face/simkai.ttf");
}
@font-face {
  font-family: Microsoft-YaHei;
  src        : url("./assets/font-face/MicrosoftYaHei.ttf");
}
 

@font-face {
  font-family: SimSun;
  src        : url("./assets/font-face/SimSun.ttf");
}



.body-scrollCtrl {
  overflow: hidden !important;
  position: fixed; // 处理安卓和IOS的兼容问题，这样也会出现一个问题，就是浮层弹出后，页面会置顶
  width: 100%;
}

.theme-star {
  --bl: 3rem;
  background: rgba(248, 248, 248, 1);
  overflow: hidden;
}
:root {
  --backgroundColor: rgba(195, 40, 43, 1);
}

$isize: 16px;
$mainBgImg: var(--bl);

html {
  // font-size: 100px !important;
}
i {
  font-style: normal;
}
</style>