<template>
  <div class="top-bar"  :class="activeShow()"    @keyup.enter="goSearch"> 
    <i
      class="el-icon-arrow-left"
      @click="goback"
      v-show="$route.path !== '/home' || searchActive"
    ></i>

    <section class="img">
      <img src="../assets/home/logoName.png" alt="" />
    </section>
    <section class="img1" v-show="$route.path == '/home' && !searchActive">
      <img src="../assets/home/logoName.png" alt="" />
    </section>

    <ul class="navigation">
      <li
        v-for="(item, index) in options"
        :class="{ active: routePs(item.linkUrl), show: item.children }"
        @mouseover="mouseover(item)"
        @click="goItem(index, item)"
        :key="item.value"
      >
        {{ item.menuName }}
        <ul
          class="navigation-level"
          :style="{
            width: `${item.children && item.children.length * 1.5}rem`,
          }"
        >
          <li
            v-for="child in item.children"
            @click.stop="goOther(child.linkUrl || child.url, child.linkType)"
            :key="child.label"
          >
            {{ child.menuName || child.storeName }}
          </li>
        </ul>
        <div class="navigation-bottom-bar"></div>
      </li>
    </ul>
    <section
      class="top_bar_title"
      @click="goTabar()"
      v-show="$route.path !== '/home'"
    >
      {{ topBarName }}
    </section>

    <el-input
      class="webInput"
      v-model="value"
      placeholder="请输入关键词"
      suffix-icon="el-icon-search"
      @blur="goSearch"
   
    >
    </el-input>
    <div class="iphoneIcon">
      <el-input
        v-if="searchActive || $route.path == '/searchMobile'"
        v-model="value"
        placeholder="请输入关键词"
      >
      </el-input>
      <i
        v-if="!searchActive && $route.path !== '/searchMobile'"
        class="el-icon-search search2"
        @click="search"
      
      ></i>
      <i v-else class="el-icon-search search3" @click="goSearchMobile"></i>
      <!-- <i
        class="el-icon-house"
        v-if="$route.path == '/home'"
        @click="showShop = !showShop"
      ></i> -->
      <img
        class="house_shop"
        src="../assets/recommend/home.jpg"
        v-if="$route.path == '/home'"
        @click="showShop = !showShop"
        alt=""
      />
      <!-- <i class="el-icon-house" v-else @click="gobackHome"></i> -->
      <img
        class="house_home"
        src="../assets/recommend/backhome.jpg"
        v-else
        @click="gobackHome"
        alt=""
      />
    </div>
    <ul class="shopBar" @click.stop="closeOpen" v-if="showShop">
      <li v-for="item in shopdataList" :key="item.id" @click="goShop(item.url)">
        {{ item.storeName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "vue-header",
  data() {
    return {
      options: [],
      option: "index",
      value: "",
      active: null,
      mouseoverItem: {},
      searchActive: false,
      topBarName: "",
      shopdataList: [],
      showShop: false,
      linkUrl: "",
      active1:0
    };
  },
  async created() {
    await this.queryshop();
    await this.query();

     await this.getinterfacestyle()
        if (this.$route.query.status) {
       this.active1 = this.$route.query.status;
     
    }
    this.$bus.$on('shopchange', res=>{
     console.log("shopchange",res)
     this.showShop = true
   })
 
  },
  methods: {
    link: function () {
      this.$router.push({ path: "/" + this.option });
    },
    async getinterfacestyle() {
      let { data: res } = await this.$http.get("/interfacestyle/homePage", {});
      console.log(res.data.list,'interfacestyle');
      if(res.data.list[0]&&res.data.list[0].fontSize){
        this.active1 = res.data.list[0].fontSize
      

        
      }
    },
     activeShow() {
      if (this.active1 == 1) {
        this.$emit('getClass','celebration')
        return "celebration";
      } else if (this.active1 == 2) {
   
   this.$emit('getClass','normal blackwhite')
        return "normal blackwhite";
      } else {
          this.$emit('getClass','normal')
        return "normal";
      }
    },
    
    goShop(url) {
      this.showShop = false;
      window.open(url, "_blank");
    },
    closeOpen() {
      this.showShop = false;
    },
    goItem(index, item) {
      this.active = index;
      if (this.$route.fullPath == item.linkUrl || !item.linkUrl) {
        return;
      }
      if (item.linkType == 1) {
        this.$router.push({ path: item.linkUrl });
      } else {
        // Window.open(item.linkUrl)
        if(item.linkUrl){
            window.open(item.linkUrl, "_blank");
        }
      
      }
    },
    mouseover(item) {
      this.mouseoverItem = item;
    },
    routePs(item) {
      console.log(item, this.$route, "路由");
      if (this.$route.path.indexOf(item) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    search() {
      this.searchActive = true;
      // this.$router.push({ path: "/searchMobile" });
    },
    goSearchMobile() {
      if (this.$router.mode.indexOf("search") !== -1) {
        let url = this.$router.resolve({
          path: "/searchMobile",
          query: { keyword: this.value },
        });
        window.open(url, "_blank");
      } else {
        this.$router.push({
          path: "/searchMobile",
          query: { keyword: this.value },
        });
      }
    },

    goOther(url, type) {
      if (type == 0 || !type) {
        window.open(url);
      } else {
        var querys = url.substring(url.indexOf("?") + 1).split("&");
        var to = url.substring(0, url.indexOf("?"));
        var result = [];
        for (var i = 0; i < querys.length; i++) {
          var temp = querys[i].split("=");
          if (temp.length < 2) {
            result[temp[0]] = "";
          } else {
            result[temp[0]] = temp[1];
          }
        }
        console.log(result[0], querys, temp, "result");

        this.$router.push({
          path: to,
          query: { publishRange: result[temp[0]] },
        });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    gobackHome() {
      this.$router.push({ path: "/" });
    },
    goSearch() {
      console.log(this.$router, "this.$router");
      if(  navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )){   
        this.goSearchMobile()
      }else {
          if (this.$router.mode.indexOf("search") !== -1) {
        let url = this.$router.resolve({
          path: "/search",
          query: { keyword: this.value },
        });
        window.open(url, "_blank");
      } else {
        this.$router.push({
          path: "/search",
          query: { keyword: this.value },
        });
      }
      }
    
      // this.$router.push({ path: "/search", query: { keyword: this.value } });
    },
    async query() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/h5sysmenu/homePage", {
        params: {
          //  ...this.dataForm
          limit: 20,
        },
      });
      // .then(({ data: res }) => {
      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }

      let level2 = res.data.list.filter((e) => e.menuLevel == 2);
      res.data.list.forEach((lis) => {
        console.log(lis, "level");
        level2.forEach((level) => {
          console.log(lis, "level", level);
          if (lis.id == level.previousMenu) {
            console.log(lis.id, "level", level.previousMenu);
            if (!lis.children) {
              lis.children = [level];
            } else {
              lis.children.push(level);
            }
          }
        });
      });
      this.options = res.data.list.filter((e) => e.menuLevel == 1);
  
      console.log(this.options, "this.options");
      this.options.forEach((opt) => {
        console.log(
          opt,
          "opr",

          this.$route,
          this.$route.linkUrl == this.$route.path
        );

        if (opt.linkUrl == this.$route.path) {
          this.topBarName = opt.menuName;
        }
        if (opt.id == 7) {
          opt.children = this.shopdataList;
        }
        
      });
      this.options =  this.options.filter(e => e.id !==7 || e.children.length !== 0 );
      // this.selectYears(this.dataList)
      // })
      // .catch((err) => {
      //   console.log(err, "err");
      // });
    },
    goTabar() {
      console.log(this.linkUrl, "this.linkUrl");
      this.$router.push({ path: "/" + this.linkUrl });
      location.reload();
    },
    async queryshop() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/flagshipstore/homePage", {
        params: {
          //  ...this.dataForm
          limit: 20,
        },
      });
      // .then(({ data: res }) => {
      if (res.code !== 0) {
        this.shopdataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      this.shopdataList = res.data.list;
      // })
      // .catch((err) => {
      //   console.log(err, "err");
      // });
    },
  },

  watch: {
    $route(to, from) {
      this.value = this.$route.query.keyword;
      this.options.forEach((opt) => {
        if (this.$route.path.indexOf(opt.linkUrl) != -1) {
          if (this.$route.path.indexOf("/recommend") != -1) {
            if (this.$route.query.publicRange == 0) {
              this.topBarName = "长销图书";
            } else if (this.$route.query.publicRange == 1) {
              this.topBarName = "新书出版";
              this.linkUrl = opt.linkUrl + `?publicRange=2`;
            } else if (this.$route.query.publicRange == 2) {
              this.topBarName = "系列专题";
              this.linkUrl = opt.linkUrl + `?publicRange=3`;
            } else {
              this.topBarName = opt.menuName;
              this.linkUrl = opt.linkUrl;
            }
            if (to.query.publicRange == 0) {
              this.linkUrl = opt.linkUrl + `?publicRange=0`;
            } else if (to.query.publicRange == 1) {
              this.linkUrl = opt.linkUrl + `?publicRange=1`;
            } else if (to.query.publicRange == 2) {
              this.linkUrl = opt.linkUrl + `?publicRange=2`;
            } else {
              this.linkUrl = opt.linkUrl;
            }
            console.log(from.query, "optssss1", this.linkUrl);
          } else if (this.$route.path.indexOf("/information") != -1) {
            console.log(from.query, "optssss", opt.linkUrl);
            if (this.$route.query.publishRange == 0) {
              this.topBarName = "图片资讯";
            } else if (this.$route.query.publishRange == 1) {
              this.topBarName = "其他资讯";
            } else {
              this.topBarName = opt.menuName;
            }
            if (to.query.publicRange == 0) {
              this.linkUrl = opt.linkUrl + `?publicRange=0`;
            } else if (to.query.publicRange == 1) {
              this.linkUrl = opt.linkUrl + `?publicRange=2`;
            } else if (to.query.publicRange == 2) {
              this.linkUrl = opt.linkUrl + `?publicRange=3`;
            } else {
              this.linkUrl = opt.linkUrl;
            }
            console.log(this.topBarName, "optssss", this.linkUrl);
          } else {
            this.topBarName = opt.menuName;
            this.linkUrl = opt.linkUrl;
          }
        }
      });

      // if (to.name == "searchMobile") {
      //   this.searchActive = true;
      // } else {
      //   this.searchActive = false;
      // }
      // this.options.forEach(opt=>{
      //   console.log(opt,'opt',opt.linkUrl == to.fullPath);

      //   if(opt.linkUrl == to.fullPath){
      //     this.topBarName = opt.menuName
      //   }
      // })
    },

    showShop(val) {
      if (val) {
        document.body.className = "body-scrollCtrl";
      } else {
        document.body.removeAttribute("class", "body-scrollCtrl");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.blackwhite{
    img{
      filter: grayscale(100%);
    }
}
 
</style>
<style lang="scss">
@import url("../assets/css/reset.css");

.top-bar {
  z-index: 9;
  width: 100%;
  box-sizing: border-box;
  height: 0.56rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.32rem;
  background: #ffffff;
  box-shadow: inset 0rem 0.01rem 0rem 0rem #eeeeee;
  width: 14.4rem;
  height: 0.56rem;
  background: #ffffff;
  box-shadow: inset 0rem -0.01rem 0rem 0rem #eeeeee;
  .shopBar {
    display: none;
  }
  .search2 {
    display: none;
  }
  .iphoneIcon {
    display: none;
  }
  .webInput {
    // display: none;
  }
  .top_bar_title {
    display: none;
  }
  .el-icon-house {
    display: none;
  }
  .el-icon-arrow-left {
    display: none;
  }

  .img {
    font-size: 0.32rem;
    height: 0.32rem;
    width: 2.63rem;
    img {
      width: 100%;
      height: 100%;
    }
    .el-input__icon {
      // top: 1px;
      // font-size: 0.14rem;
      // color: rgba(255, 255, 255, 0.65);
    }
  }
  .img1 {
    display: none;
  }

  .el-input {
    width: 2.5rem;
    height: 0.32rem;
  }

  .el-input__inner {
    width: 2.5rem;
    height: 0.32rem;
    font-size: 0.14rem;
  }

  .el-input__icon {
    line-height: 0.32rem;
    color: var(--theme-color);
  }
  .navigation {
    display: flex;
    // width: 7.34rem;
    justify-content: space-between;
    height: 100%;
    li {
      display: flex;
      position: relative;
      padding: 0 0.24rem;
      font-size: 0.14rem;
      height: 100%;
      line-height: 0.56rem;

      color: rgba(0, 0, 0, 0.65);

      // color: rgba(255, 255, 255, 0.65);
      cursor: pointer;
    }
    .active {
      background: var(--theme-color);
      font-size: 0.14rem;
      line-height: 0.56rem;
      font-weight: 500;
      color: #ffffff;
    }
    li:hover {
      // color: #ffffff;
      background: var(--theme-color);
      font-size: 0.14rem;
      line-height: 0.56rem;
      font-weight: 500;
      color: #ffffff;
    }
    .show:hover {
      .navigation-bottom-bar {
        position: fixed;
        // bottom: -0.48rem;
        top: 0.56rem;

        left: 0;
        width: 100vw;
        height: 0.48rem;
        background: var(--theme-color);
      }
      .navigation-level {
        display: flex;
      }
    }
  }
  .navigation-level {
    z-index: 9;

    display: none;
    position: absolute;
    height: 0.48rem;
    align-items: center;
    justify-content: center;
    bottom: -0.48rem;
    left: 50%;
    transform: translate(-50%);
    li {
      // width: 0.8rem;
      padding: 0;
      text-align: center;
      padding:0  0.12rem;
      font-weight: 400;

      color: #ffffff;
      font-size: 0.14rem;
    }
    li:hover {
      font-weight: 500;
      color: #ffffff;
      font-size: 0.14rem;
    }
  }
}
@media (max-width: 768px) {
  .top-bar {
    height: 0.55rem;
    background: #ffffff;
    box-shadow: inset 0rem -0.01rem 0rem 0rem #eeeeee;
    padding: 0 0.16rem;
    width: 3.75rem;
    position: relative;
    .house_home {
      width: 0.2rem;
      height: 0.2rem;
      padding: 0.02rem;
      margin-left: 0.1rem;
    }
    .house_shop {
      width: 0.24rem;
      height: 0.24rem;
      margin-left: 0.1rem;
    }
    .shopBar {
      display: block;
      position: fixed;
      top: 0.55rem;
      width: 100%;
      left: 0;
      height: 100vh;
      width: 3.75rem;
      height: 7.12rem;
      background: rgba(0, 0, 0, 0.7);

      li {
        height: 0.54rem;
        line-height: 0.54rem;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);

        text-align: center;

        background: #ffffff;
        border-bottom: 1px solid #eeeeee;
      }
    }
    .webInput {
      display: none;
    }
    .img {
      display: none;
    }
    .img1 {
      display: block;
      img {
        width: 1.97rem;
        height: 0.24rem;
      }
    }

    .el-input {
      width: 2.79rem;
      height: 0.32rem;
      border-radius: 0.04rem;
      border: 0.01rem solid #eeeeee;
    }
    .el-input__inner {
      width: 2.79rem;
      height: 0.32rem;
      border-radius: 0.04rem;
      border: 0.01rem solid #eeeeee;
      font-size: 0.14rem;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 0.22rem;
    }
    .el-icon-arrow-left {
      display: inline-block;
      color: var(--theme-color);
      font-weight: 600;
      font-size: 0.2rem;
    }
    .el-icon-search {
      display: inline-block;
      font-size: 0.2rem;
      color: var(--theme-color);
      padding: 0.06rem 0;
      &::before {
        content: "";
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        background-image: url("../assets/recommend/search.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .top_bar_title {
      display: block;
      font-size: 0.16rem;
      // width: 0.64rem;
      text-align: center;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .iphoneIcon {
      display: flex;
      height: 0.32rem;
      align-items: center;
    }
    .search2 {
      margin-right: 0.06rem;
    }
    .search3 {
      position: absolute;
      right: 0.55rem;
      width: 0.2rem;
      height: 0.2rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../assets/recommend/search.png");
      padding: 0;
    }
    .el-icon-house {
      display: inline-block;
      font-size: 0.2rem;
      margin-left: 0.11rem;
      color: var(--theme-color);
    }

    .navigation {
      display: none;
    }
  }
  .blackwhite{
    .el-icon-search::before{
  
      filter: grayscale(100%);
    }
  }
}
</style>