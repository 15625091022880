import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/vue-index.vue'

Vue.use(VueRouter)
const routes = [
  {

    path: '/',

    redirect:'/home',
 
  },
  {

    path: '/home',

 
    name: 'Home',
    component: Home
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('../components/vue-index.vue')
  },
  {
    path: '/recommend',
    name: 'Recommend',

    component: () => import('../views/recommend/index.vue')
  },
  {
    path: '/recommend/bookdetail',
    name: 'BookDetail',

    component: () => import('../views/bookDetail/index.vue')
  },
  {
    path: '/recommend/seriesdetail',
    name: 'seriesdetail',

    component: () => import('../views/bookDetail/series.vue')
  },
  {
    path: '/magazine',
    name: 'magazine',

    component: () => import('../views/magazine/index.vue')
  },
  {
    path: '/magazine/magazinedetail',
    name: 'magazineDetail',

    component: () => import('../views/magazineDetail/index.vue')
  },
  {
    path: '/convergencemedia',
    name: 'convergencemedia',

    component: () => import('../views/convergencemedia/index.vue')
  },
  {
    path: '/convergencemedia/media',
    name: 'media',

    component: () => import('../views/convergencemedia/media.vue')
  },
  {
    path: '/information',
    name: 'information',

    component: () => import('../views/information/index.vue')
  },
  {
    path: '/information/informationdetail',
    name: 'informationdetail',

    component: () => import('../views/information/informationDetail.vue')
  },
  {
    path: '/information/picinformationdetail',
    name: 'picinformationdetail',

    component: () => import('../views/information/picinformationdetail.vue')
  },

  {
    path: '/contact',
    name: 'contact',

    component: () => import('../views/contact/index.vue')
  },
  {
    path: '/contactDetail',
    name: 'contactDetail',

    component: () => import('../views/contact/detail.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',

    component: () => import('../views/aboutus/index.vue')
  },
  {
    path: '/aboutusdetail',
    name: 'aboutusdetail',

    component: () => import('../views/aboutus/detail.vue')
  },
  {
    path: '/download',
    name: 'download',

    component: () => import('../views/download/index.vue')
  },
  {
    path: '/search',
    name: 'search',

    component: () => import('../views/search/index.vue')
  },
  
  {
    path: '/download/detail',
    name: 'detail',

    component: () => import('../views/download/detail.vue')
  },
  {
    path: '/pcBooks',
    name: 'pcBooks',

    component: () => import('../views/pcBooks/index.vue')
  },
  {
    path: '/searchMobile',
    name: 'searchMobile',

    component: () => import('../views/search/iphone.vue')
  }
]
 
 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {window.scrollTo(0,0);
})

export default router
