<template>
  <div class="footer" :class="className">
    <section class="msg">
      <div class="msg_title">友情链接</div>
      <div class="msg_content">
        <ul>
          <li
            v-for="item in friendlylinkList"
            :key="item.id"
            @click="goOutUrl(item.url)"
          >
            {{ item.siteName }}
          </li>
        </ul>
      </div>
    </section>
    <section class="us">
      <div class="contact">
        <div class="msg_title">联系我们</div>
        <ul>
          <li
            style="cursor: pointer"
            v-for="item in contactusdataList"
            :key="item.id"
            @click="goContactus(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="relative">
        <div class="msg_title">关于我们</div>
        <ul>
          <li
            v-for="item in aboutsdataList"
            :key="item.id"
            @click="aboutus(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </section>
    <section class="qrCode">
      <div class="msg_title">
        <div>社交媒体</div>
        <div class="buttons" v-if="qrcodeTotal > 3">
          <span  :class="page>1?'active':''" @click="getless()">
            <i class="el-icon-back"></i>
          </span>
          <span :class="page*3<qrcodeTotal?'active':''" @click="getMore()">
            <i class="el-icon-right"></i>
          </span>
        </div>
      </div>

      <div class="qrcode_content">
        <figure
          v-for="item in dataList"
          @click="goSocialMedia(item.url)"
          :key="item.id"
          :class="item.url ? '':'noneactive'"
        >
          <img :src="item.picUrl" alt="" />
          <span>{{ item.mediaName }}</span>
        </figure>
      </div>
    </section>

    <div class="bottom_msg">

      <div>版权所有 © 中共党史出版社 <span v-if="flag">-</span> </div>
      <div>All Rights Reserved<span v-if="flag">.</span></div>
       
      <div> 京ICP备18029718号-1</div>
     
    </div>
    <div
      class="backTopIcon"
      @click="backTop()"
      v-if="
        backTopFlag == '/recommend' ||
        backTopFlag == '/information' ||
        backTopFlag == '/convergencemedia'
      "
    >
      <img src="../assets/recommend/backTopicon.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "vue-footer",
  data() {
    return {
      msg: "",
      dataList: [],
      qrcodeTotal:0,
      page:1,
      contactusdataList: [],
      friendlylinkList: [],
      total: 0,
      tableData: [],
      homePagedataFrom: {
        status: 1,
      },
      goPage: "",
      aboutsdataList: [],
      flag:true
      // backTopFlag:false
    };
  },
  props: {
    className: String,
  },
  created() {
    this.homePagequery();
    this.contactusquery();
    this.friendlylink();
    this.aboutsquery();
         if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
         this.flag = false
        } else {
          console.log("11");
        }
  },
  computed: {
    backTopFlag: function () {
      // console.log(this.$route.path,'this.$route.path');
      return this.$route.path;
    },
  },

  methods: {
    expandsHandle(row) {
      this.$refs.expandstable.toggleRowExpansion(row);
    },
    goSocialMedia(url) {
      if(url){
          window.open(url, "_target");
       }
    
    },
    aboutus(id) {
      this.$router.push({ path: "/aboutus", query: { id: id } });
    },
    backTop() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(this.backTop);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    },

    goContactus(id) {
      this.$router.push({ path: "/contact", query: { id: id } });
    },
    aboutsquery() {
      this.$http
        .get("/aboutus/homePage", {
          params: {
            status: 1,
            limit: 1000,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.aboutsdataList = [];

            return this.$message.error(res.msg);
          }
          this.aboutsdataList = res.data.list;
        })
        .catch(() => {});
    },
    goOutUrl(url) {
      window.open(url, "_blank");
    },
    friendlylink() {
      this.$http
        .get("/friendlylink/homePage", {
          params: {
            status: 1,
            limit: 1000,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.friendlylinkList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.friendlylinkList = res.data.list;
          console.log(this.dataList, "homePage");
          this.dataFrom.total = res.data.total;
        })
        .catch(() => {});
    },
    getMore(){
      if(this.page * 3 < this.qrcodeTotal){
        this.page += 1
        this.homePagequery()
      }

    },
      getless(){
      if(this.page > 1){
        this.page -= 1
        this.homePagequery()
      }

    },
    homePagequery() {
      console.log("resdata");

      this.$http
        .get("/socialmedia/homePage", {
          params: {
            status: 1,
            limit: 3,
            page:this.page
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.list;
          console.log(this.dataList, "homePage");
          this.qrcodeTotal = res.data.total;
        })
        .catch(() => {});
    },
    contactusquery() {
      this.$http
        .get("/contactus/homePage", {
          params: {
            status: 1,
            limit: 100,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.contactusdataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.contactusdataList = res.data.list;
        })
        .catch(() => {});
    },

    go() {
      this.dataFrom.page = this.goPage;
      this.query();
    },

    selectType(status) {
      this.dataFrom.status = status;
      this.query();
    },
    select(type) {
      this.dataFrom.type = type;
      this.query();
    },
    pageCurrentChangeHandle(index) {
      this.dataFrom.page = index;
      this.query();
    },
    decode(base64) {
      // // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
  },
  watch: {
    // dataFrom: {
    //   handler(newVal) {
    //     let pages = Math.ceil(this.total / newVal.limit);
    //     this.goPage = this.dataFrom.page
    //     if (pages === 0) {
    //       // 数据(totalResult)为0
    //       this.dataFrom.firstPageDisabled = true; // 首页按钮是否禁用
    //       this.dataFrom.lastPageDisabled = true; // 末页按钮是否禁用
    //     } else {
    //       this.dataFrom.firstPageDisabled = newVal.page === 1;
    //       this.dataFrom.lastPageDisabled = newVal.page === pages;
    //       console.log(
    //         pages,
    //         this.dataFrom.firstPageDisabled,
    //         this.dataFrom.lastPageDisabled,
    //         "pages"
    //       );
    //     }
    //   },
    //   // 一进页面就执行
    //   immediate: true,
    //   deep: true,
    // },
  },
};
</script>
<style scoped  lang="scss">
.blackwhite {
  img {
    filter: grayscale(100%);
  }
}
</style>
<style lang="scss">
.footer {
  position: relative;
  min-height: 3rem;
  background: #f6f6f6;
  box-shadow: inset 0rem 0.01rem 0rem 0rem #eeeeee;
  padding: 0.48rem 0.64rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .backTopIcon {
    display: none;
  }
  .content {
    margin-top: 30px;
    p {
      margin-top: 14px;
    }
  }
  .tel {
    margin-top: 30px;
    img {
      padding: 10px 0 0 10px;
      width: 100px;
    }
  }
  .msg_title {
    font-size: 0.2rem;
    // display: flex;
    // justify-content: space-between;
    font-weight: 500;

    color: rgba(0, 0, 0, 0.85);
    line-height: 0.28rem;
    margin-bottom: 0.24rem;
  }
  .msg {
    .msg_content {
      display: flex;
      ul {
        min-width: 1.52rem;
        width: 3.2rem;
        display: flex;
        flex-wrap: wrap;
      }
      li {
        cursor: pointer;
        min-width: 1.52rem;
        font-size: 0.14rem;
        margin-bottom: 0.08rem;
        font-weight: 400;
        // color: rgba(255,255,255,0.65);

        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
    }
  }
     .qrCode {
    
      .msg_title {
        display: flex;
        justify-content: space-between;
        .buttons{
          span{
            margin-left:10px;
            color: rgba(0, 0, 0, 0.25);
            cursor: pointer;
          }
          .active{
             color:var(--theme-color);
             font-weight: 500;
          }

        }
     
      }
    
    }
  .qrcode_content {
    display: flex;
    .noneactive{
      cursor:auto ;
    }
  }

  figure {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      display: inline-block;
      width: 100%;
      text-align: center;

      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.22rem;
    }
  }
  figure:nth-child(3) {
    margin-right: 0;
  }
  .us {
    min-width: 3.4rem;

    display: flex;
    justify-content: space-between;
    ul {
      li {
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
        margin-bottom: 0.08rem;
      }
    }
    .contact {
      // display: flex;
      ul {
        min-width: 1.52rem;
        width: 3.2rem;
        display: flex;
        flex-wrap: wrap;
      }
      li {
        cursor: pointer;
        min-width: 1.52rem;
        font-size: 0.14rem;
        margin-bottom: 0.08rem;
        font-weight: 400;
        // color: rgba(255,255,255,0.65);

        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
    }
  }
  .bottom_msg {
    position: absolute;
    font-size: 0.12rem;
    display: flex;
  
    
    font-weight: 400;

    color: rgba(0, 0, 0, 0.45);
    line-height: 0.2rem;
    bottom: 0.2rem;
    left: 50%;
    transform: translate(-50%);
  }
}
ul {
  li {
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    height: auto;
    padding: 0.4rem 0.28rem;
    .backTopIcon {
      display: none;
      position: fixed;
      bottom: 1rem;
      right: 0.1rem;
      img {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
    .msg {
      .msg_title {
        font-size: 0.2rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.28rem;
      }
      .msg_content {
        flex-direction: column;
        ul {
          min-width: 0;
          display: flex;
          flex-wrap: wrap;
        }
        li {
          width: auto;
          min-width: 1rem;
          font-size: 0.14rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 0.22rem;
          margin-right: 0.24rem;
          margin-bottom: 0.16rem;
        }
        // ul:nth-child(2){
        //   display: none;
        // }
      }
    }
    .us {
      flex-direction: column;
      .contact {
        margin-top: 0.24rem;
        ul {
          display: flex;
          li {
            margin: 0;
            margin-right: 0.24rem;
            min-width: 1rem;
          }
        }
      }
      .relative {
        margin-top: 0.4rem;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 0;
            margin-right: 0.24rem;
            min-width: 1rem;
          }
        }
      }
    }
    .qrCode {
      margin-top: 0.4rem;
      margin-bottom: 1.32rem;
      // .msg_title {
      //   display: flex;
      //   justify-content: space-between;
     
      // }
      figure:after {
        margin-top: 0.08rem;
        display: block;
        text-align: center;
        content: "党史出版社";
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
    }
    .bottom_msg {
      flex-direction: column;
      width: 1.74rem;
      font-size: 0.12rem;
  text-align: center;
      font-weight: 400;

      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      bottom: 0.4rem;
    }
  }
}
</style>
