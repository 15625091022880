<template>
  <div class="index-body" :class="activeShow()">
    <section class="top_out">
      <div class="swiper-container container">
        <div class="swiper-wrapper">
          <div
            v-for="item in dataList"
            :key="item.id"
            @click="goSwiper(item.linkUrl)"
            :class="item.linkUrl ? 'con_active_swiper' : ''"
            class="swiper-slide content"
          >
            <img :src="item.picUrl" alt="" />
          </div>
          <!-- <div class="swiper-slide content">
          <img src="../assets/home/swiper1.png" alt="" />
        </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="swiper-container siwoer_nav">
        <!-- <div>{{options}}</div> -->
        <div class="swiper-wrapper">
          <div
            v-for="item in options"
            :key="item.id"
            @click="goItem(item)"
            v-show="item.menuName !== '首页'"
            class="swiper-slide"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.menuName }}</span>
          </div>
          <!-- <div class="swiper-slide">
          <img src="../assets/nav/zixun.png" alt="" />
          <span>资讯</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/books.png" alt="" />
          <span>图书推荐</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/magazine.png" alt="" />
          <span>期刊</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/media.png" alt="" />
          <span>融媒体</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/pcBooks.png" alt="" />
          <span>电子书</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/books.png" alt="" />
          <span>下载中心</span>
        </div>
        <div class="swiper-slide">
          <img src="../assets/nav/books.png" alt="" />
          <span>积分商城</span>
        </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <div class="oneBack">
      <section class="information">
        <div class="info_swiper">
          <div class="swiper-wrapper">
            <div
              v-for="item in picInfoDataList"
              :key="item.id"
              @click="goPicInfo(item.id)"
              class="swiper-slide info_slide"
            >
              <img :src="item.titlePicUrl" alt="" />
              <span class="title">{{ item.title }}</span>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <!-- <span>地铁上的读书人｜拍北京地铁上的读书人第五</span> -->
        </div>
        <div class="txt_info">
          <div class="info_header">
            <span>资讯</span>
            <span style="cursor: pointer" @click="checkMoreInfo"
              >查看更多<i class="el-icon-right"></i
            ></span>
          </div>

          <ul class="info_msg">
            <!-- <li class="active">
            <span>标题最多一行超出部分用…</span>
            <span>2023-10-10</span>
          </li> -->
            <li
              v-for="item in otherInfoDataList"
              :class="item.isTop == 1 ? 'info_msg_childActive' : ''"
              :key="item.id"
              @click="goInfoDetail(item.id)"
            >
              <span>{{ item.title }}</span>
              <span>{{
                item.publishTime && item.publishTime.substring(0, 10)
              }}</span>
            </li>
          </ul>
          <el-tabs
            v-model="activeName"
            class="phoneInfo"
            @tab-click="handleClick"
          >
            <el-tab-pane class="picInfo" label="图片资讯" name="0">
              <ul>
                <li
                  @click="goPicInfo(item.id)"
                  v-for="item in picInfoDataList"
                  :key="item.id"
                >
                  <figure><img :src="item.titlePicUrl" alt="" /></figure>
                  <div>
                    <span>{{ item.title }}</span>
                    <i>{{ item.createDate }}</i>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane class="otherInfo" label="其他资讯" name="1">
              <ul>
                <li
                  @click="goInfoDetail(item.id)"
                  v-for="item in otherInfoDataList"
                  :key="item.id"
                >
                  <span>{{ item.title }}</span
                  ><i class="el-icon-arrow-right"></i>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </section>
      <section class="info infoMore">
        <div class="Icon">
          <figure
            @click="goremcommend(booksdataListTopOne.id, recommendactive)"
          >
            <img
              :src="booksdataListTopOne && booksdataListTopOne.coverUrl"
              alt=""
            />
          </figure>
          <div class="icon_name">
            <span>{{
              booksdataListTopOne &&
              booksdataListTopOne.bookName &&
              booksdataListTopOne.bookName
            }}</span>
          </div>
        </div>
        <div class="recommend">
          <div class="info_header">
            <span>图书推荐</span>
            <div class="info_button">
              <span
                class="left"
                :class="booksdataForm.page == 1 ? '' : 'active'"
                @click="less()"
              >
                <i class="el-icon-back"></i
              ></span>
              <span
                class="right"
                :class="
                  4 * booksdataForm.page >= booksdataForm.total - 1
                    ? ''
                    : 'active'
                "
                @click="more()"
              >
                <i class="el-icon-right"></i
              ></span>
              <span @click="goBooks()"
                >查看更多<i class="el-icon-right"></i
              ></span>
            </div>
          </div>

          <ul class="text_nav">
            <li
              :class="
                recommendactive === returnActive(item, index) ? 'active' : ''
              "
              v-for="(item, index) in recommend"
              :key="item.name"
              @click="seleceRecommend(index, 'reset', item)"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="remcommend_pid">
            <li
              v-for="item in booksdataList.slice(
                1 + (booksdataForm.page - 1) * 4,
                5 + (booksdataForm.page - 1) * 4
              )"
              @click="goremcommend(item.id, recommendactive)"
              :key="item.id"
            >
              <figure>
                <img :src="item.coverUrl && item.coverUrl" alt="" />
              </figure>
              <span>{{ item.bookName }}</span>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <div class="twoBack">
      <section class="info magazine_recommend">
        <div class="recommend">
          <div class="info_header">
            <span>期刊</span>
            <div class="info_button">
              <span
                class="left"
                @click="goleft()"
                :class="dataForm.page !== 1 ? 'active' : ''"
              >
                <i class="el-icon-back"></i
              ></span>
              <span
                class="right"
                @click="goright()"
                :class="
                  dataForm.page * dataForm.limit < dataForm.total
                    ? 'active'
                    : ''
                "
              >
                <i class="el-icon-right"></i
              ></span>
              <span @click="checkMoreMagazine()"
                >查看更多<i class="el-icon-right"></i
              ></span>
            </div>
          </div>

          <ul class="text_nav">
            <li class="active">百年潮</li>
          </ul>
          <ul class="remcommend_pid">
            <li
              v-for="item in managzineShowDataList()"
              @click="gomagazine(item.id)"
              :key="item.id"
            >
              <figure>
                <img :src="item.coverUrl && item.coverUrl" alt="" />
              </figure>
              <span style="text-align: center">{{
                item.magazineNumberType == 1
                  ? item.year + "年" + item.magazineTotalNumber
                  : item.year +
                    " - " +
                    (item.magazineNumber > 9
                      ? item.magazineNumber
                      : "0" + item.magazineNumber)
              }}</span>
            </li>
          </ul>
          <div class="magazine_swiper">
            <div class="swiper-wrapper">
              <div
                v-for="item in managzineShowDataList()"
                :key="item.id"
                @click="gomagazine(item.id)"
                class="swiper-slide magazine_slide"
              >
                <figure>
                  <img :src="item.coverUrl && item.coverUrl" alt="" />
                </figure>

                <span class="title">百年潮</span>
                <span class="time">{{
                  item.magazineNumberType == 1
                    ? item.year + "年" + item.magazineTotalNumber
                    : item.year +
                      " - " +
                      (item.magazineNumber > 10
                        ? item.magazineNumber
                        : "0" + item.magazineNumber)
                }}</span>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <span>地铁上的读书人｜拍北京地铁上的读书人第五</span> -->
          </div>
        </div>
        <div class="Icon" @click="gomagazine(magazineTop.id)">
          <figure>
            <img :src="magazineTop.coverUrl && magazineTop.coverUrl" alt="" />
          </figure>
          <div class="icon_name">
            <span>{{
              magazineTop.magazineNumberType == 1
                ? magazineTop.year + "年" + magazineTop.magazineTotalNumber
                : magazineTop.year + "年第" + magazineTop.magazineNumber + "期"
            }}</span>
          </div>
        </div>
      </section>
      <section class="media">
        <figure
          class="bigOne"
          v-for="(item, index) in mediadataList"
          :key="index"
          v-show="index == 0"
          @click="goMediadetail(item.id)"
        >
          <img :src="item.picUrl || picUrlcheck(item.fileUrl, item)" alt="" />
          <span>{{ item.title }}</span>
        </figure>
        <!-- <video :src="mediadataList[0].picUrl"></video> -->
        <div class="media_msg">
          <div class="Icon">
            <span>融媒体</span>
            <div class="check_more" @click="gomedia()">
              查看更多 <i class="el-icon-right"></i>
            </div>
          </div>
          <ul class="media_all">
            <li
              v-for="item in mediadataList.slice(1, yidong? 5:4)"
              @click="goMediadetail(item.id)"
              :key="item.id"
            >
              <figure>
                <img
                  :src="item.picUrl || picUrlcheck(item.fileUrl, item)"
                  alt=""
                />
              </figure>
              <div class="side">
                <span class="title">{{ item.title }}</span>
                <span class="msg">{{ decode(item.content) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import music from "../assets/home/music.png";
import img1 from "../assets/nav/1.png";
import img2 from "../assets/nav/2.png";
import img3 from "../assets/nav/3.png";
import img4 from "../assets/nav/4.png";
import img5 from "../assets/nav/5.png";
import img6 from "../assets/nav/6.png";
import img7 from "../assets/nav/7.png";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
export default {
  name: "vue-index",
  //   components: {
  //   swiper,
  //   swiperSlide
  // },

  data() {
    return {
      srcs: [
        { src: img1 },
        { src: img2 },
        { src: img3 },
        { src: img4 },
        { src: img5 },
        { src: img6 },
        { src: img7 },
      ],
      booksdataListTopOne: {},

      msg: "",
      activeName: 0,
      dataList: [],
      otherInfoDataList: [],
      dataForm: {
        page: 1,
        limit: 5,
      },
      active: 0,
      booksdataList: [],
      mediadataList: [],
      mediaForm: {
        page: 1,
        limit: 5,
        status: 1,
      },
      booksdataForm: {
        page: 1,
        limit: 5,
        status: 1,
        publishRange: 0,
      },
      recommendactive: "1",
      managzineDataList: [],
      yidong:false,

      magazineTop: {},
      options: [],
      picInfoDataList: [],
      recommend: [],
      music: music,
    };
  },
  async mounted() {
    let _this = this;
    await this.getinterfacestyle();
    if (this.$route.query.status) {
      this.active = this.$route.query.status;
      // console.log(this.active,this.$route.query,'###');
    }
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      this.yidong = true
    }
    await this.querySwiper();
    this.queryOtherInfo();
    await this.queryManazine();
    await this.queryHeader();
    await this.queryPicInfo();
    await this.queryMedia();
    console.log(this.recommend[0], "this.recommend[0]");
    await this.seleceRecommend(null, true, this.recommend[0]);

    this.magazineTop = this.managzineDataList[0];

    // 设置主题色
    // --theme-color是自定义的按钮背景样式代号
    // 可动态修改，eg:由后端返回接受，可由前端事件修改

    // new Swiper(".info_swiper1", {
    //   loop: true,
    //   autoplay:{
    //      delay: 1000
    //   },
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   // 如果需要前进后退按钮
    //   // nextButton: ".swiper-button-next",
    //   // prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: '.swiper-scrollbar',
    //   //如果需要自动切换海报
    //   // autoplay: {
    //   //   delay: 1000,//时间 毫秒
    //   //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
    //   // },
    // });
    setTimeout(() => {
      new Swiper(".container", {
        loop: true,
        autoplay:
          _this.dataList.length > 1
            ? {
                delay: 5000,
                disableOnInteraction: true,
              }
            : false,
        pagination: {
          //  ".swiper-pagination"
          el: ".swiper-pagination",
          clickable: true,
        },
        // 如果需要分页器

        // 如果需要前进后退按钮
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
        // },
      });
      new Swiper(".siwoer_nav", {
        // loop: true,
        // autoplay:{
        //    delay: 1000
        // },
        // 如果需要分页器
        pagination: {
          //  ".swiper-pagination"
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 5.3,
        // 如果需要前进后退按钮
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
        // },
      });

      new Swiper(".magazine_swiper", {
        // loop: true,
        // autoplay: {
        //   delay: 1000,
        // },
        // 如果需要分页器
        slidesPerView: 2.3,
        spaceBetween: 5,
        // pagination: ".swiper-pagination",
        // 如果需要前进后退按钮
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
        // },
      });

      // var swiper = new Swiper(".mySwiper", {
      //   spaceBetween: 30,
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true,
      //   },
      // });

      new Swiper(".info_swiper", {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        // 如果需要分页器
        // pagination:".swiper-pagination",
        pagination: {
          //  ".swiper-pagination"
          el: ".swiper-pagination",
          clickable: true,
        },
        // 如果需要前进后退按钮
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
        // },
      });
    }, 1000);
    // this.nextTick(() => {});

    new Swiper(".books_recommend_swiper", {
      loop: true,
      // 如果需要分页器
      slidesPerView: 4,
      spaceBetween: 30,

      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
      //如果需要自动切换海报
      // autoplay: {
      //   delay: 1000,//时间 毫秒
      //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      // },
    });
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    // activeShow(){
    //   if(this.active ==1 ){
    //     return 'celebration'
    //   }else if(this.active == 2){
    //     return 'blackwhite'
    //    }else {
    //     return ''
    //    }
    // }
    // rActive(){
    //   // return true
    //   // console.log(this.dataForm.page ,this.dataForm.limit , this.dataForm.total,'this.dataForm.page * this.dataForm.limit < this.dataForm.total');
    //   return this.dataForm.page * this.dataForm.limit < this.dataForm.total
    // },
  },
  methods: {
    handleClick() {},
    checkMoreInfo() {
      this.$router.push({ path: "/information" });
    },
    async getinterfacestyle() {
      let { data: res } = await this.$http.get("/interfacestyle/homePage", {});
      console.log(res.data.list, "interfacestyle");
      if (res.data.list[0] && res.data.list[0].fontSize) {
        this.active = res.data.list[0].fontSize;
      }
    },
    picUrlcheck(Url, item) {
      let URL = JSON.parse(Url)[0];
      let urlcheck = URL.response.substring(URL.response.lastIndexOf(".") + 1);
      console.log(urlcheck, "URL", URL);
      if (urlcheck == "mp3") {
        console.log("mp3");
        return this.music;
      } else {
        return item.firstFramePicUrl;
      }
    },
    activeShow() {
      if (this.active == 1) {
        console.log("####", "celebration");
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("--theme-color", "#b20103");
        return "celebration";
      } else if (this.active == 2) {
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("--theme-color", "#949494");
        console.log("####", "blackwhite");
        return "normal blackwhite";
      } else {
        document
          .getElementsByTagName("body")[0]
          .style.setProperty("--theme-color", "#b20103");
      
        return "normal";
      }
    },
    goPicInfo(id) {
      this.$router.push({
        path: "/information/picinformationdetail",
        query: { id: id },
      });
    },
    gomedia() {
      this.$router.push({ path: "/convergencemedia" });
    },
    goMediadetail(id) {
      this.$router.push({
        path: "/convergencemedia/media",
        query: { id: id },
      });
    },
    gomagazine(id) {
      this.$router.push({
        path: "/magazine/magazinedetail",
        query: { id: id },
      });
    },
    goremcommend(id, recommendactive) {
      if (recommendactive == 2) {
        this.$router.push({
          path: "/recommend/seriesdetail",
          query: { id: id, publicRange: recommendactive },
        });
      } else {
        this.$router.push({
          path: "/recommend/bookdetail",
          query: { id: id, publicRange: recommendactive },
        });
      }
    },
    checkMoreMagazine() {
      this.$router.push({ path: "/magazine" });
    },
    more() {
      console.log(
        "more",
        this.booksdataForm,
        4 * (this.booksdataForm.page + 1)
      );
      if (4 * this.booksdataForm.page < this.booksdataForm.total - 1) {
        console.log("more1");
        this.booksdataForm.page += 1;
        // this.seleceRecommend(this.recommendactive);s
      }
    },
    less() {
      console.log("less");
      if (this.booksdataForm.page > 1) {
        console.log("less2");
        this.booksdataForm.page -= 1;
        // this.seleceRecommend(this.recommendactive);
      }
    },
    returnActive(item, index) {
      if (item && item.linkType === "1") {
        if (item.linkUrl) {
          return this.getQueryVariable(item.linkUrl, "publishRange");
        }
      } else if (item) {
        return +index;
      }
    },
    getQueryVariable(url, variable) {
      var query = url.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
      // ['https://qq.com','appid=1234567890&name=Zdf']
      var vars = query.split("&"); // 根据“&”切割成数组
      // ['appid=1234567890','name=Zdf']
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // ['appid','1234567890']
        if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
      }
      return false;
    },
    seleceRecommend(index, reset, item) {
      if (item && item.linkType === "0") {
        window, open(item.linkUrl);
        return;
      }

      if (item && item.linkType === "1") {
        if (item.linkUrl) {
          this.recommendactive = this.getQueryVariable(
            item.linkUrl,
            "publishRange"
          );
          this.booksdataForm.publishRange = this.getQueryVariable(
            item.linkUrl,
            "publishRange"
          );
          console.log(
            item,
            this.recommendactive,
            this.booksdataForm.publishRange,
            "12222"
          );
        }
      }

      if (reset) {
        this.booksdataForm.page = 1;
      }
      console.log(this.recommendactive, "this.recommendactive");
      if (this.recommendactive === "2") {
        this.booksdataForm.limit = 13;

        this.queryBookseries(reset);
      } else {
        this.booksdataForm.limit = 1000;

        this.queryBooks(reset);
      }
    },
    async queryBooks(reset) {
      let { data: res } = await this.$http.get("/books/homePage", {
        params: {
          ...this.booksdataForm,
          status: 1,
        },
      });

      if (res.code !== 0) {
        this.booksdataList = [];

        return this.$message.error(res.msg);
      }
      this.booksdataForm.total = res.data.total > 13 ? 13 : res.data.total;
      this.booksdataList = res.data.list;
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.booksdataList.unshift(this.booksdataList[0]);
        console.log("managzineDataList", this.booksdataList);
      } else {
        console.log("11");
      }
      if (reset) {
        this.booksdataListTopOne = this.booksdataList[0];
      }
    },
    async queryMedia() {
      let { data: res } = await this.$http.get("/convergencemedia/homePage", {
        params: { status: 1 },
      });

      if (res.code !== 0) {
        this.mediadataList = [];

        return this.$message.error(res.msg);
      }

     console.log(this.mediadataList, "mediadataList");

      this.mediadataList = res.data.list;
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        if(this.mediadataList.length > 0){
            this.mediadataList.unshift(this.mediadataList[0]);
        }
      
      } else {
        console.log("11");
      }
        console.log(this.mediadataList, "mediadataList");
    },
    async queryBookseries(reset) {
      let { data: res } = await this.$http.get("/seriessubject/homePage", {
        params: {
          ...this.booksdataForm,
          status: 1,
        },
      });

      if (res.code !== 0) {
        this.booksdataList = [];

        return this.$message.error(res.msg);
      }
      this.booksdataForm.total = res.data.total;
      this.booksdataList = res.data.list;
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.booksdataList.unshift(this.booksdataList[0]);
        console.log("managzineDataList", this.booksdataList);
      } else {
        console.log("11");
      }
      if (reset) {
        this.booksdataListTopOne = this.booksdataList[0];
      }
    },
    managzineShowDataList() {
      return this.managzineDataList.slice(
        1 + (this.dataForm.page - 1) * 4,
        5 + (this.dataForm.page - 1) * 4
      );
    },
    goleft() {
      if (this.dataForm.page <= 1) {
        return;
      }
      this.dataForm.page -= 1;

      // this.queryManazine();
    },
    goright() {
      if (this.dataForm.page * this.dataForm.limit < this.dataForm.total) {
        this.dataForm.page += 1;
        // this.managzineShowDataList = this.managzineDataList.slice(1+ (this.dataForm.page -1) * 4,5 + (this.dataForm.page -1) * 4)
        // this.queryManazine();
      }
    },

    goInfoDetail(id) {
      this.$router.push({
        path: "/information/informationdetail",
        query: { id: id },
      });
    },
    goBooks() {
      this.$router.push({
        path: `/recommend?publishRange=${this.recommendactive}`,
      });
    },
    async queryManazine() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          ...this.dataForm,
          limit: 10000000,
          status: 1,
        },
      });

      if (res.code !== 0) {
        this.managzineDataList = [];
        this.dataForm.total = 0;
        return this.$message.error(res.msg);
      }
      this.managzineDataList = res.data.list;
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.managzineDataList.unshift(this.managzineDataList[0]);
        console.log("managzineDataList", this.managzineDataList);
      } else {
        console.log("11");
      }
      // this.managzineShowDataList = res.data.list.slice(1,5)
      this.dataForm.total = res.data.total;
    },
    querySwiper() {
      this.$http
        .get("/slideshow/homePage", {
          params: { status: 1 },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.list;
        })
        .catch(() => {});
    },
    queryOtherInfo() {
      this.$http
        .get("/information/homePage", {
          params: {
            limit: 8,
            status: 1,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.otherInfoDataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.otherInfoDataList = res.data.list;
        })
        .catch(() => {});
    },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
    queryPicInfo() {
      this.$http
        .get("/picinformation/homePage", {
          params: {
            limit: 5,
            status: 1,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.picInfoDataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.picInfoDataList = res.data.list;
        })
        .catch(() => {});
    },
    goSwiper(url) {
      if (url) {
        window.open(url);
      }
    },
    goItem(item) {
      if (this.$route.fullPath == item.linkUrl || !item.linkUrl) {
        return;
      }
      if (item.menuName == "旗舰店") {
        this.$bus.$emit("shopchange", true);
        return;
      }

      console.log(item, "11111");
      if (item.linkType == 1) {
        this.$router.push({ path: item.linkUrl });
      } else {
        // Window.open(item.linkUrl)
        window.open(item.linkUrl, "_blank");
      }
    },
    async queryHeader() {
      console.log("resdata");
      let _this = this;
      const { data: res } = await this.$http.get("/h5sysmenu/homePage", {
        params: {
          //  ...this.dataForm
          limit: 20,
          status: 1,
        },
      });
      // .then(({ data: res }) => {
      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }

      let level2 = res.data.list.filter((e) => e.menuLevel == 2);
      res.data.list.forEach((lis) => {
        console.log(lis, "level");
        level2.forEach((level) => {
          console.log(lis, "level", level);
          if (lis.id == level.previousMenu) {
            console.log(lis, "level", level);
            if (!lis.children) {
              lis.children = [level];
            } else {
              lis.children.push(level);
            }
          }
        });
      });
      this.options = res.data.list.filter((e) => e.menuLevel == 1);
      this.options = this.options.filter((e) => e.id !== 7);
      this.options.forEach((opt, i) => {
        console.log(
          opt,
          "opt",
          i,
          this.$route,
          this.$route.linkUrl == this.$route.path
        );
        // Vue.set(opt,'pic', `../assets/nav/${i}.png`)
        // opt.pic =  `../assets/nav/${i}.png`

        if (opt.linkUrl == this.$route.path) {
          this.topBarName = opt.menuName;
        }
        if (opt.linkUrl == "/recommend") {
          _this.recommend = [];
          opt.children.forEach((el) => {
            _this.recommend.push({ name: el.menuName, ...el });
          });
        }
      });
      console.log(this.options, res.data.list, "this.options ");
      // this.selectYears(this.dataList)
      // })
      // .catch((err) => {
      //   console.log(err, "err");
      // });
    },
  },
};
</script>
<style lang="scss">
img {
  max-width: 100%;
}
.index-body {
  margin-top: 0.56rem;
  .swiper-pagination-bullet {
    width: 0.32rem;
    height: 0.06rem;
    background: #ffffff;
    border-radius: 0;
  }
}
.information {
  display: flex;
  .info_swiper {
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      height: 0.3rem;
      width: 7rem;
      position: absolute;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 0) 0%,
        rgba(0, 0, 0, 0.9) 100%
      );
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0.24rem;
      text-align: right;
      padding-right: 0.15rem;
      box-sizing: border-box;
      .swiper-pagination-bullet {
        width: 0.06rem;
        height: 0.06rem;
        border-radius: 0.06rem;
        background: #ffffff;
        margin: 0 2px;
      }
    }
  }
}
.blackwhite {
  img {
    filter: grayscale(100%);
  }
  // .top-bar .el-icon-search::before{
  //     background-blend-mode: hue;
  // }
}
@media (max-width: 768px) {
  .siwoer_nav {
    display: block;
    width: 3.45rem;
    height: 1.31rem;
    background: #ffffff;
    border-radius: 0.08rem;

    .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        margin: 0 !important;
        width: 0.5rem;
        height: 0.03rem;
        background: #eeeeee;
        border-radius: 0.02rem;
      }
      .swiper-pagination-bullet-active {
        width: 0.5rem;
        height: 0.03rem;
        background: var(--theme-color);
        border-radius: 0.02rem;
      }
      .swiper-pagination-bullet:nth-child(2) {
        display: none;
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .phoneInfo {
    margin-top: 0.16rem;
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__item {
      font-size: 0.14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
    }
    .el-tabs__item.is-active {
      color: rgba(0, 0, 0, 0.85);
    }
    .el-tabs__active-bar {
      width: 0.24rem !important;
      height: 0.03rem;
      background: var(--theme-color);
      bottom: 0.1rem;
      left: 10%;
      border-radius: 0.02rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.index-body {
  background: #ffffff;
}
.content {
  margin: 0 auto;
  img {
    width: 100%;

    height: 6.8rem;
  }
}
.magazine_swiper {
  display: none;
}
.siwoer_nav {
  display: none;
}
.container {
  height: 6.8rem;
  position: relative;
  .con_active_swiper {
    cursor: pointer;
  }
  // cursor: pointer;
}
.publishStatus {
  position: absolute;
  z-index: 5;
  transform: translate(-50%, 0);
  left: 50%;
  top: 3.15rem;
  font-size: 1rem;
  font-weight: normal;
  color: #ffffff;
}

.information {
  box-sizing: border-box;

  height: 6.9rem;
  background: rgba(178, 1, 3, 0.02);

  // // opacity: 0.02;

  padding: 1.2rem 0.64rem;
  .phoneInfo {
    display: none;
  }
  .info_swiper {
    width: 7rem;
    height: 4.5rem;
    background: rgba(0, 0, 0, 0.85);
    overflow: hidden;
    .swiper-wrapper {
      width: 7rem;
      height: 4.5rem;
      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 0.06rem;
          height: 0.06rem;
          background: #ffffff;
        }
      }
      img {
        height: 100%;
        width: 100%;
      }
      .info_slide {
        position: relative;
        .title {
          width: 5.5rem;
          position: absolute;
          bottom: 0.16rem;
          left: 0.24rem;
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.22rem;
          display: inline-block;
          overflow: hidden; // 溢出隐藏

          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }
      }
    }
  }
  .txt_info {
    margin-left: 0.8rem;
    width: 5.22rem;
    margin-bottom: 0.25rem;
    .info_header {
      display: flex;
      justify-content: space-between;

      span:first-child {
        font-size: 0.48rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: var(--theme-color);
        line-height: 0.56rem;
      }
      span:nth-child(2) {
        width: 1.12rem;
        height: 0.32rem;
        background: #ffffff;
        border: 0.01rem solid var(--theme-color);
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--theme-color);
        line-height: 0.22rem;
        line-height: 0.32rem;
        text-align: center;
        i {
          margin-left: 0.08rem;
        }
      }
    }

    .info_msg {
      padding-top: 0.08rem;
      li {
        display: flex;
        justify-content: space-between;
        margin-top: 0.24rem;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 0.24rem;
        &.info_msg_childActive {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
        }
        span {
          max-width: 3.5rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
      .active,
      li:hover {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
.books_recommend {
  box-sizing: border-box;
  height: 7.3rem;
  padding: 1.17rem 1rem;
  display: flex;
  text-align: center;

  background: #ffffff;
  figure {
    width: 3.56rem;
    height: 4.96rem;
    margin-right: 0.75rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .books {
    width: 7.88rem;
    position: relative;

    .Icon {
      height: 0.67rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.56rem;
      figure {
        width: 1.92rem;
        height: 0.67rem;
      }
      .check_more {
        width: 1.12rem;
        height: 0.4rem;
        line-height: 0.4rem;
        background: #c3282b;
        border-radius: 0.02rem;
        font-weight: 400;
        color: #ffffff;
        font-size: 0.14rem;
      }
    }
  }
  .books_recommend2 {
    display: flex;
    justify-content: space-between;
    height: 0.4rem;
    margin-bottom: 0.22rem;

    .button {
      width: 1.76rem;
      font-size: 0.18rem;

      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.24rem;

      display: flex;
      justify-content: space-between;
    }
  }
  .books_recommend_swiper {
    width: 7.88rem;
    overflow: hidden;
    .swiper-wrapper {
      width: 100%;
    }
    .recommend_button {
      position: absolute;
      right: 0;
      top: 1.15rem;
      display: flex;
      height: 0.4rem;
      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        left: 0;
        right: 0;
        width: 0.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.26rem;
        background: #ffffff;
        border-radius: 0.02rem;
        border: 0.01rem solid rgba(0, 0, 0, 0.25);
        color: rgba(0, 0, 0, 0.25);
      }
      .swiper-button-prev {
        margin-right: 0.16rem;
      }
    }
  }
  .recommend {
    img {
      width: 1.76rem;
      height: 2.45rem;
    }
  }
}
.magazine {
  flex-direction: row-reverse;
  background: #fffafa;
  padding-left: 1.23rem;
  figure {
    margin-right: 0;
  }
  .books {
    .Icon {
      figure {
        width: 1.12rem;
      }
    }
  }

  .books {
    margin-right: 0.75rem;
  }
}
.media {
  height: 6.9rem;
  width: 100vw;
  background: #ffffff;
  box-sizing: border-box;
  padding: 1.2rem 0.64rem;
  display: flex;

  // justify-content: space-between;
  video {
    width: 7rem;
    height: 4.5rem;
    background: red;
    margin-right: 0.8rem;
  }
  .bigOne {
    width: 7rem;
    height: 4.5rem;

    margin-right: 0.8rem;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      width: 0.73rem;
      height: 0.73rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAAXNSR0IArs4c6QAABQFJREFUaEPVmXmsXVMUxn9fjWkNQYgxGkGChJCmFP8g5qgh0UbaaEIpKQ2KpDGUVgwVtDWEShtDSZqghiAkIhFUNRXzEDTGEIqYYvbJOvZ5Tm/vvee8e27vu3Zy85J3z177t9fdZ621vyX+p0Pd5ra9MbAVsAHwjaQfu71G2KsFbntzYH/gAGB7YEtgvQAGfgO2BjYCvk3/exlYJunzupsZNLjt9YFjgYOAnYGVwPPApwEo6ecilO0N04ZiEwenTf4CxCYekPRDJ5uoDG57WFp4EvA+8DTwuqQ/B7uw7d3Sxo8AHgYekRS/UOVRCdz2rsC5wF/AIklvVl6hzYO2twVOBfYC7pL0bFW7peC24+c9G7hV0otVDQ/mueSY6cBy4G5JLpvfFtz2BOAwYJakj8qM1fne9gjgEuBX4CpJf7ez1xLc9nHA0cAMSd/Xgao613ZEpAeB8Ph4Sb+3mtsU3PbxwGTgZEnhgZ4O2xemPHBtq2OzFrjtfYHzgemSvu4pcVoshdyrgdck3deMYQ1w29sB1wMzJX04FND5munM35SizVpBoRH8PGC1pMVDCV2A3weYGlFNUoTigTEAbntkvM3AGZIis/XFsD07lQlPtAKfCbwi6bG+IP7vvO8CXJkcOhAoMo+nFHwRMKVK8O/1xmzHEf5Y0tJ87Rz8FGC4pIW9hqqynu2oQMdKigSVjRx8HrBA0ltVDPX6mVRhRsA4TdJPGbjtKDcDfEKdY2J7OLCZpC/XxcZsXwq8kBdiAX4UsLuk+XUWtH1mqtMfAu6X9Ecde41zbR8CjJIUeSbzeJzvYa0yVNXFbUfZG/V1jLhUzJP0XtX5Zc/Zjpg+Lj/nAR4BfpWkJ8smt/u+ATwLVnFBAO5tVyxVXdP2TlE9Sjor9/jlwFOSohbueDQBz219Acyve/lIJUBcYsbn4HOT4VUdU/+bC6YBh7ewEd6fLOmrmmssASZFxRpH5Zr0Mr1R02gr8IB+PIXb0ptNK4Z0540X/6S4ZAT4xcBLkp6rCV58Obt6TLIXxg7pY66kuKNmUSUuDCHcDKTTTjbQcMbDs48C93TjxUzgoQxMlRTpPwM/MXSPuum+AP5ZCoXvduKANkcl0v6Rkmbl4KNTHRCZqeNhew9gz/B0t5NP8nhEkxGSFuXgoTRFHXD6utL5OvZGYaLtm4HbJL2TgafdzAhJTNIz3Vik2zZsbwPENW5iXk/l4IeGpicpLqh9N2yPBUYW66kcfFPgDmCapNX9RJ7i9y0pD7yasxXvnOPSrub0GfgxwBhJlxW5iuChYy8AZkv6oB/gU5PgziSXrFGSNMoTIbuNbtzdUG3CdoTAUB/mNF5yGsFDA48AH2VuFi+HatiOLscU4AJJ3zVyNJPgNgFuBJYMVXhMqsMV6Yg0PbatRM8d4+cBbpAUrZKejRSzQ224TlK0aJqOdjLzDsDtKQz1RCRKno7So7Q7USbsB3woXMvrFmFlP5ntA4FzIkNKWlH2fJVWSnQKoiSIZ8MT0bjq2rAdPdGJwN4htUn6pIrxUvAwkrJX3OBDEXgbWFg3wyaRJ+yFPBKC5tJc7OkaeG4oLXYCEJ+ot5el4qxSqyXN3y/1OkelXudiSdHAHdSo5PFGi7YjZAbAmPQ3QlbWoC18on8TxyCuXPE3FLOYE1pLKAor6qheHYEXN5LaHiHWREt8iwJodKCLG4k2+crGzvOg3Fx4+B/n4/8+uwEcZQAAAABJRU5ErkJggg==)
        no-repeat center;
      background-size: 100% 100%;
      transform: translate(-50%, -50%);
    }
    span {
      z-index: 9;
      width: 5.5rem;
      position: absolute;
      bottom: 0.1rem;
      left: 0.24rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
      line-height: 0.22rem;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &::before {
      content: "";
      display: block;
      height: 0.5rem;
      width: 7rem;
      position: absolute;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(
        180deg,
        hsla(0, 0%, 93.3%, 0),
        rgba(0, 0, 0, 0.9)
      );
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .media_msg {
    width: 5.32rem;
    ul {
      margin-top: 0.43rem;
      border-top: 1px solid #eeeeee;
      padding-top: 0.24rem;
    }
    li {
      margin-bottom: 0.2rem;
      display: flex;
      figure {
        flex-shrink: 0;
        flex-basis: auto;
        width: 1.4rem;
        height: 0.9rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .side {
        margin-left: 0.26rem;
        span {
          display: inline-block;
        }
        .title {
          height: 0.24rem;
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 0.24rem;
          padding-bottom: 0.08rem;
          width: 3.6rem;
          overflow: hidden; // 溢出隐藏
          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }
        .msg {
          height: 0.24rem;
          width: 3.11rem;
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 0.24rem;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; // 默认不换行；
          width: 3.6rem;
        }
      }
    }
  }

  .Icon {
    height: 0.67rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.32rem;
    span {
      font-size: 0.4rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: var(--theme-color);
      line-height: 0.56rem;
    }
    .check_more {
      // width: 1.12rem;
      // height: 0.4rem;
      // line-height: 0.4rem;
      // background: #c3282b;
      // border-radius: 0.02rem;
      // font-weight: 400;
      // color: #ffffff;
      // text-align: center;
      // font-size: 0.14rem;
      // margin-left: 0.13rem;
      width: 1.12rem;
      height: 0.32rem;
      background: #ffffff;
      border: 0.01rem solid var(--theme-color);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--theme-color);
      cursor: pointer;
      line-height: 0.32rem;
      text-align: center;
    }
  }
}
.info {
  width: 14.4rem;
  height: 6.12rem;

  background: #ffffff;
  padding: 1.2rem 0.64rem;
  box-sizing: border-box;
  display: flex;
  .Icon {
    // display: flex;
    // align-items: flex-end;
    // height: 3.52rem;
    cursor: pointer;
    .icon_name {
      margin-top: 0.16rem;
      text-align: left;
      // width: 2rem;
         width: 3.52rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      // writing-mode: vertical-rl; /* 设置文字方向为竖排从右向左 */
      //  text-orientation: upright; /* 设置文字正立 */
      // word-spacing: 3px;
      margin-right: 0.32rem;
      // letter-spacing: 0.3em;
      cursor: pointer;
      span {
        // display: flex;
        display: inline-block;
        width: 3.52rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        // width: 0.2rem;
        // width: 2rem;
        // height: 2rem;
        // text-align: center;

        //     transform-origin:left bottom;
        // transform: rotateX(90deg);
        // writing-mode: tb-rl;
        // word-wrap: break-word;

        font-size: 0.14rem;
        // height: 2rem;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.25rem;
      }
      i {
        display: inline-block;
        width: 0.02rem;
        height: 0.24rem;
        background: var(--theme-color);
        margin-top: 0.09rem;
        margin-left: 0.13rem;
      }
    }

    figure {
      width: 3.52rem;
      height: 3.52rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border: 1px solid #f5f5f5;
      background: #ffffff;
      img {
        height: 90%;
      }
    }
  }
  .recommend {
    width: 100%;
    margin-left: 0.32rem;
    .info_header {
      display: flex;
      justify-content: space-between;

      span:first-child {
        font-size: 0.48rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: var(--theme-color);
        line-height: 0.56rem;
      }
      .info_button {
        display: flex;
        margin-right: 0.32rem;
        span {
          cursor: pointer;
          display: block;
          background: #ffffff;
          border-radius: 0.02rem;
          border: 0.01rem solid rgba(0, 0, 0, 0.25);
          width: 0.32rem;
          height: 0.32rem;
          margin-left: 0.1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 0.14rem;
            color: rgba(0, 0, 0, 0.25);
          }
          &.active {
            border: 0.01rem solid var(--theme-color);
            color: var(--theme-color);
            i {
              color: var(--theme-color);
            }
          }
        }
        span:nth-child(3) {
          width: 1.12rem;
          height: 0.32rem;
          background: #ffffff;
          border: 0.01rem solid var(--theme-color);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--theme-color);
          line-height: 0.22rem;
          line-height: 0.32rem;
          text-align: center;
          i {
            margin-left: 0.08rem;
            color: var(--theme-color);
          }
        }
      }
    }
    .text_nav {
      display: flex;
      margin-top: 0.25rem;
      li {
        margin-right: 0.32rem;
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 0.24rem;
      }
      .active {
        color: var(--theme-color);
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 0.24rem;
          height: 0.04rem;
          background: var(--theme-color);
          border-radius: 0.02rem;
          position: absolute;
          bottom: -0.09rem;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
  .remcommend_pid {
    display: flex;
    margin-top: 0.45rem;
    margin-right: 0.3rem;
    width: 8.91rem;
    li {
      margin-right: 0.3rem;
    }
    li:last-child {
      margin-right: 0;
    }
    figure {
      width: 2rem;
      height: 2rem;
      background: #ffffff;
      border: 0.01rem solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.16rem;

      img {
        height: 90%;
      }
    }
    span {
      display: inline-block;
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;

      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      width: 2rem;
    }
  }
}
.magazine_recommend {
  background: rgba(178, 1, 3, 0.02);
  .icon_name {
    // margin-left: 0.24rem;
    width: 100%;
    text-align: center !important;
  }
  .recommend {
    margin-left: 0;
  }
  .info_button {
    // margin-right: .32rem;
  }
}

@media (max-width: 768px) {
  .index-body {
    background: rgba(246, 246, 246, 1);
    margin-top: 0;
  }
  .normal {
    .container {
      width: 3.45rem;
      height: 1.63rem;
      // background: var(--theme-color);
      border-radius: 0.1rem;
      margin: 0.14rem auto;
      .swiper-pagination {
        display: none;
      }
      .content img {
        height: 100%;
      }
    }
    .siwoer_nav {
      display: block;
      width: 3.45rem;
      box-sizing: border-box;
      padding-left: 0.2rem;
      height: 1.31rem;
      background: #ffffff;
      border-radius: 0.08rem;
      margin-bottom: 0.1rem;
      .swiper-wrapper {
        // margin-left: .19rem;
        // margin-right: .2rem;
        margin-top: 0.2rem;
        // width: 0.48rem;
        // height: 0.44rem;
        .swiper-slide {
          // width: auto;
          // width: .62rem;
          // margin-left: .08rem;
          span {
            display: block;
            width: 0.48rem;
            text-align: center;
            margin-top: 0.1rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.18rem;
          }
        }

        img {
          width: 0.44rem;
          height: 0.44rem;
        }
      }
      .swiper-pagination-bullet {
        width: 1rem;
        height: 0.03rem;
        background: #eeeeee;
        border-radius: 0.02rem;
      }
    }
  }
  .information {
    // margin-top: 0.1rem;
    // width: 3.75rem;
    padding: 0.24rem 0.15rem;
    height: 5.8rem;
    background: #ffffff;
    border-radius: 0.08rem 0.08rem 0rem 0rem;
    .info_swiper {
      display: none;
    }
    .txt_info {
      margin: 0;
      .info_header {
        span:first-child {
          font-size: 0.2rem;

          font-weight: 500;
          color: var(--theme-color);
          line-height: 0.28rem;
        }
        span:nth-child(2) {
          width: 0.78rem;
          height: 0.28rem;
          border-radius: 0.14rem;
          border: 0.01rem solid var(--theme-color);
          font-size: 0.14rem;

          font-weight: 400;

          line-height: 0.28rem;
          .el-icon-right {
            display: none;
          }
        }
      }
      ul {
        display: none;
      }
      .phoneInfo {
        display: block;
        .picInfo {
          ul {
            display: block;
            li {
              display: flex;
              margin-bottom: 0.16rem;
              figure {
                width: 1.2rem;
                height: 0.76rem;
                background: rgba(0, 0, 0, 0.85);
                border-radius: 0.04rem;
                overflow: hidden;
                // opacity: 0.3;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              div {
                width: 2.09rem;
                margin-left: 0.16rem;
                span {
                  font-size: 0.14rem;

                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.9);
                  line-height: 0.22rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  /* 设置宽度和高度 */
                  width: 2.09rem;
                }
                i {
                  font-size: 0.12rem;
                  display: block;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.45);
                  line-height: 0.17rem;
                  margin-top: 0.08rem;
                }
              }
            }
          }
        }

        .otherInfo {
          ul {
            display: block;
            border-top: 1px solid #eeeeee;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 0.54rem;
              border-bottom: 1px solid #eeeeee;
              line-height: 0.54rem;
              font-size: 0.14rem;

              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              &:first-child {
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
              span {
                width: 3rem;
                overflow: hidden; // 溢出隐藏
                white-space: nowrap; // 强制一行
                text-overflow: ellipsis; // 文字溢出显示省略号
              }
              .el-icon-arrow-right::before {
                font-size: 0.14rem;
                color: var(--theme-color);
              }
            }
          }
        }
      }
    }
  }
  .info {
    margin-top: 0.1rem;
    width: 3.75rem;
    height: 5.68rem;
    background: #ffffff;
    padding: 0.26rem 0.15rem;

    .Icon {
      display: none;
    }

    .recommend {
      margin-left: 0;
      .text_nav {
        margin-top: 0.16rem;
      }

      .info_header {
        span:first-child {
          font-size: 0.2rem;

          font-weight: 500;
          color: var(--theme-color);
          line-height: 0.28rem;
        }
        .info_button {
          margin-right: 0;

          span {
            display: none;
          }

          span:nth-child(3) {
            display: block;
            width: 0.78rem;
            height: 0.28rem;
            line-height: 0.28rem;
            border-radius: 0.14rem;
            border: 0.01rem solid var(--theme-color);
            i {
              display: none;
            }
          }
        }
      }
      .text_nav {
        li {
          font-size: 0.14rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          margin-right: 0.16rem;
        }
        .active {
          &::after {
            width: 0.24rem;
            height: 0.03rem;
            background: var(--theme-color);
            border-radius: 0.02rem;
            bottom: -0.05rem;
          }
        }
      }
      .remcommend_pid {
        margin-top: 0.24rem;
        width: 3.45rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-right: 0;
        li {
          // box-sizing: border-box;
          margin-bottom: 0.1rem;
          margin-right: 0;
          width: 1.68rem;
          height: 2.1rem;
          background: #ffffff;
          border-radius: 0.04rem;
          border: 0.01rem solid #eeeeee;
          figure {
            width: 1.68rem;
            height: 1.68rem;
            margin-bottom: 0;
            border: none;
          }
          span {
            width: 1.68rem;
            text-align: center;
          }
        }
      }
    }
  }
  .magazine_recommend {
    height: 3.28rem;
    .text_nav {
      display: none !important;
    }
    .remcommend_pid {
      display: none !important;
    }
    .magazine_swiper {
      margin-top: 0.24rem;
      width: 3.45rem;
      overflow: hidden;
      display: block;
      .magazine_slide {
        display: flex;
        flex-direction: column;
        height: 2.26rem;
        width: 1.5rem !important;
        background: #ffffff;
        border-radius: 0.04rem;
        border: 0.01rem solid #eeeeee;
        figure {
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          border-bottom: 0.01rem solid #eeeeee;
          img {
            height: 90%;
            max-width: 1.5rem;
          }
        }
        .title {
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.22rem;
          margin-top: 0.1rem;
          text-align: center;
        }
        .time {
          font-size: 0.12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 0.22rem;
          margin-top: 0.12rem;
          text-align: center;
        }
      }
    }
  }
  .media {
    margin: 0;
    margin-top: 0.1rem;
    padding: 0.26rem 0.15rem;
    padding-bottom: 0;
    height: auto;
    .bigOne {
      display: none;
    }
    video {
      display: none;
    }
    .media_msg {
      .Icon {
        margin-bottom: 0;
        height: auto;
        span {
          font-size: 0.2rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: var(--theme-color);
          line-height: 0.28rem;
        }
        .check_more {
          width: 0.78rem;
          height: 0.28rem;
          line-height: 0.28rem;
          border-radius: 0.14rem;
          border: 0.01rem solid var(--theme-color);
          background-color: #fff;
          color: var(--theme-color);
          i {
            display: none;
          }
        }
      }
      .media_all {
        margin-top: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        li {
          flex-direction: column;
          // flex-wrap: wrap;
          width: 49%;
          figure {
            width: 1.7rem;
            height: 1.08rem;
            border-radius: 0.08rem;
            overflow: hidden;
            position: relative;

            // &:after {
            //       position: absolute;
            //       left: 50%;
            //       top: 50%;
            //       content: "";
            //       width: 0.23rem;
            //       height: 0.23rem;
            //       background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAAXNSR0IArs4c6QAABQFJREFUaEPVmXmsXVMUxn9fjWkNQYgxGkGChJCmFP8g5qgh0UbaaEIpKQ2KpDGUVgwVtDWEShtDSZqghiAkIhFUNRXzEDTGEIqYYvbJOvZ5Tm/vvee8e27vu3Zy85J3z177t9fdZ621vyX+p0Pd5ra9MbAVsAHwjaQfu71G2KsFbntzYH/gAGB7YEtgvQAGfgO2BjYCvk3/exlYJunzupsZNLjt9YFjgYOAnYGVwPPApwEo6ecilO0N04ZiEwenTf4CxCYekPRDJ5uoDG57WFp4EvA+8DTwuqQ/B7uw7d3Sxo8AHgYekRS/UOVRCdz2rsC5wF/AIklvVl6hzYO2twVOBfYC7pL0bFW7peC24+c9G7hV0otVDQ/mueSY6cBy4G5JLpvfFtz2BOAwYJakj8qM1fne9gjgEuBX4CpJf7ez1xLc9nHA0cAMSd/Xgao613ZEpAeB8Ph4Sb+3mtsU3PbxwGTgZEnhgZ4O2xemPHBtq2OzFrjtfYHzgemSvu4pcVoshdyrgdck3deMYQ1w29sB1wMzJX04FND5munM35SizVpBoRH8PGC1pMVDCV2A3weYGlFNUoTigTEAbntkvM3AGZIis/XFsD07lQlPtAKfCbwi6bG+IP7vvO8CXJkcOhAoMo+nFHwRMKVK8O/1xmzHEf5Y0tJ87Rz8FGC4pIW9hqqynu2oQMdKigSVjRx8HrBA0ltVDPX6mVRhRsA4TdJPGbjtKDcDfEKdY2J7OLCZpC/XxcZsXwq8kBdiAX4UsLuk+XUWtH1mqtMfAu6X9Ecde41zbR8CjJIUeSbzeJzvYa0yVNXFbUfZG/V1jLhUzJP0XtX5Zc/Zjpg+Lj/nAR4BfpWkJ8smt/u+ATwLVnFBAO5tVyxVXdP2TlE9Sjor9/jlwFOSohbueDQBz219Acyve/lIJUBcYsbn4HOT4VUdU/+bC6YBh7ewEd6fLOmrmmssASZFxRpH5Zr0Mr1R02gr8IB+PIXb0ptNK4Z0540X/6S4ZAT4xcBLkp6rCV58Obt6TLIXxg7pY66kuKNmUSUuDCHcDKTTTjbQcMbDs48C93TjxUzgoQxMlRTpPwM/MXSPuum+AP5ZCoXvduKANkcl0v6Rkmbl4KNTHRCZqeNhew9gz/B0t5NP8nhEkxGSFuXgoTRFHXD6utL5OvZGYaLtm4HbJL2TgafdzAhJTNIz3Vik2zZsbwPENW5iXk/l4IeGpicpLqh9N2yPBUYW66kcfFPgDmCapNX9RJ7i9y0pD7yasxXvnOPSrub0GfgxwBhJlxW5iuChYy8AZkv6oB/gU5PgziSXrFGSNMoTIbuNbtzdUG3CdoTAUB/mNF5yGsFDA48AH2VuFi+HatiOLscU4AJJ3zVyNJPgNgFuBJYMVXhMqsMV6Yg0PbatRM8d4+cBbpAUrZKejRSzQ224TlK0aJqOdjLzDsDtKQz1RCRKno7So7Q7USbsB3woXMvrFmFlP5ntA4FzIkNKWlH2fJVWSnQKoiSIZ8MT0bjq2rAdPdGJwN4htUn6pIrxUvAwkrJX3OBDEXgbWFg3wyaRJ+yFPBKC5tJc7OkaeG4oLXYCEJ+ot5el4qxSqyXN3y/1OkelXudiSdHAHdSo5PFGi7YjZAbAmPQ3QlbWoC18on8TxyCuXPE3FLOYE1pLKAor6qheHYEXN5LaHiHWREt8iwJodKCLG4k2+crGzvOg3Fx4+B/n4/8+uwEcZQAAAABJRU5ErkJggg==) no-repeat center;
            //       background-size: 100% 100%;
            //       transform: translate(-50%, -50%);
            //   }
          }
          figure::after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            width: 0.23rem;
            height: 0.23rem;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAAXNSR0IArs4c6QAABQFJREFUaEPVmXmsXVMUxn9fjWkNQYgxGkGChJCmFP8g5qgh0UbaaEIpKQ2KpDGUVgwVtDWEShtDSZqghiAkIhFUNRXzEDTGEIqYYvbJOvZ5Tm/vvee8e27vu3Zy85J3z177t9fdZ621vyX+p0Pd5ra9MbAVsAHwjaQfu71G2KsFbntzYH/gAGB7YEtgvQAGfgO2BjYCvk3/exlYJunzupsZNLjt9YFjgYOAnYGVwPPApwEo6ecilO0N04ZiEwenTf4CxCYekPRDJ5uoDG57WFp4EvA+8DTwuqQ/B7uw7d3Sxo8AHgYekRS/UOVRCdz2rsC5wF/AIklvVl6hzYO2twVOBfYC7pL0bFW7peC24+c9G7hV0otVDQ/mueSY6cBy4G5JLpvfFtz2BOAwYJakj8qM1fne9gjgEuBX4CpJf7ez1xLc9nHA0cAMSd/Xgao613ZEpAeB8Ph4Sb+3mtsU3PbxwGTgZEnhgZ4O2xemPHBtq2OzFrjtfYHzgemSvu4pcVoshdyrgdck3deMYQ1w29sB1wMzJX04FND5munM35SizVpBoRH8PGC1pMVDCV2A3weYGlFNUoTigTEAbntkvM3AGZIis/XFsD07lQlPtAKfCbwi6bG+IP7vvO8CXJkcOhAoMo+nFHwRMKVK8O/1xmzHEf5Y0tJ87Rz8FGC4pIW9hqqynu2oQMdKigSVjRx8HrBA0ltVDPX6mVRhRsA4TdJPGbjtKDcDfEKdY2J7OLCZpC/XxcZsXwq8kBdiAX4UsLuk+XUWtH1mqtMfAu6X9Ecde41zbR8CjJIUeSbzeJzvYa0yVNXFbUfZG/V1jLhUzJP0XtX5Zc/Zjpg+Lj/nAR4BfpWkJ8smt/u+ATwLVnFBAO5tVyxVXdP2TlE9Sjor9/jlwFOSohbueDQBz219Acyve/lIJUBcYsbn4HOT4VUdU/+bC6YBh7ewEd6fLOmrmmssASZFxRpH5Zr0Mr1R02gr8IB+PIXb0ptNK4Z0540X/6S4ZAT4xcBLkp6rCV58Obt6TLIXxg7pY66kuKNmUSUuDCHcDKTTTjbQcMbDs48C93TjxUzgoQxMlRTpPwM/MXSPuum+AP5ZCoXvduKANkcl0v6Rkmbl4KNTHRCZqeNhew9gz/B0t5NP8nhEkxGSFuXgoTRFHXD6utL5OvZGYaLtm4HbJL2TgafdzAhJTNIz3Vik2zZsbwPENW5iXk/l4IeGpicpLqh9N2yPBUYW66kcfFPgDmCapNX9RJ7i9y0pD7yasxXvnOPSrub0GfgxwBhJlxW5iuChYy8AZkv6oB/gU5PgziSXrFGSNMoTIbuNbtzdUG3CdoTAUB/mNF5yGsFDA48AH2VuFi+HatiOLscU4AJJ3zVyNJPgNgFuBJYMVXhMqsMV6Yg0PbatRM8d4+cBbpAUrZKejRSzQ224TlK0aJqOdjLzDsDtKQz1RCRKno7So7Q7USbsB3woXMvrFmFlP5ntA4FzIkNKWlH2fJVWSnQKoiSIZ8MT0bjq2rAdPdGJwN4htUn6pIrxUvAwkrJX3OBDEXgbWFg3wyaRJ+yFPBKC5tJc7OkaeG4oLXYCEJ+ot5el4qxSqyXN3y/1OkelXudiSdHAHdSo5PFGi7YjZAbAmPQ3QlbWoC18on8TxyCuXPE3FLOYE1pLKAor6qheHYEXN5LaHiHWREt8iwJodKCLG4k2+crGzvOg3Fx4+B/n4/8+uwEcZQAAAABJRU5ErkJggg==)
              no-repeat center;
            background-size: 100% 100%;
            transform: translate(-50%, -50%);
          }
          .side {
            margin: 0;
          }
          .title {
            margin-top: 0.1rem;
            font-size: 0.14rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.22rem;
            padding-bottom: 0;
            display: block;
            width: auto;
          }
          .msg {
            display: none;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.celebration {
  // #cc585a
  // #e6aead
  // #ffffff
  .info .Icon .icon_name span {
    color: #fff2df;
  }
  .oneBack {
    background: url("../assets/recommend/informationBackground.png") no-repeat;
    background-size: 100% 100%;
    .info {
      background: none;
    }
    .info_header {
      span:first-child {
        color: rgba(255, 242, 223, 1);
      }
      span:nth-child(2) {
        background: rgba(255, 242, 223, 1);
      }
    }
    .icon_name {
      span {
        color: rgba(255, 242, 223, 1);
      }
    }

    .information {
      // margin-top: -.01rem;
      // background: linear-gradient(180deg, #d88284 0%, #e6aead 100%);

      .info_msg {
        li {
          span {
            color: rgba(255, 242, 223, 1);
          }
        }
      }
    }
    .recommend .text_nav .active {
      color: rgba(255, 242, 223, 1);
      font-weight: 600;
      &::after {
        background: rgba(255, 242, 223, 1);
      }
    }
    .recommend .text_nav li {
      font-weight: 400;
      color: #fff2df;
    }
    .recommend .info_header .info_button span.active {
      background: rgba(255, 242, 223, 1);
    }
    .recommend .info_header .info_button span {
      background: #fff2df;
    }
  }
  .info_header .info_button span:nth-child(3) {
    background: #fff2df;
  }
  .Icon figure {
    background: #fff2df;
  }
  .remcommend_pid figure {
    background: #fff2df;
  }
  .twoBack {
    background: url("../assets/recommend/booksBackground.png") no-repeat;
    background-size: 100% 100%;
    .media {
      background: none;
      .Icon span {
        color: #fff2df;
      }
      .Icon .check_more {
        background: #fff2df;
      }
      .side span {
        color: #fff2df;
      }
      .media_msg ul {
        border-top: 1px solid #fff2df;
      }
    }
    .info_button {
      span {
        background: #fff2df !important;
      }
    }
  }
  .remcommend_pid span {
    color: #fff2df;
  }
}

@media (max-width: 768px) {
  .celebration {
    // #cc585a
    // #e6aead
    // #ffffff
    .container {
      width: 3.45rem;
      height: 1.63rem;
      background: var(--theme-color);
      border-radius: 0.1rem;
      margin: 0.14rem auto;
      .swiper-pagination {
        display: none;
      }
      .content img {
        height: 100%;
      }
    }
    .siwoer_nav {
      display: block;
      width: 3.45rem;
      box-sizing: border-box;
      padding-left: 0.2rem;
      height: 1.31rem;
      background: #ffffff;
      border-radius: 0.08rem;
      .swiper-wrapper {
        // margin-left: .19rem;
        // margin-right: .2rem;
        margin-top: 0.2rem;
        // width: 0.48rem;
        // height: 0.44rem;
        .swiper-slide {
          // width: auto;
          // width: .62rem;
          // margin-left: .08rem;
          span {
            display: block;
            width: 0.48rem;
            text-align: center;
            margin-top: 0.1rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.18rem;
          }
        }

        img {
          width: 0.44rem;
          height: 0.44rem;
        }
      }
      .swiper-pagination-bullet {
        width: 1rem;
        height: 0.03rem;
        background: #eeeeee;
        border-radius: 0.02rem;
      }
    }

    .information {
      background: var(--theme-color);
      border-radius: 0;
      margin-top: -1px;
      .info_header {
        span:first-child {
          color: #fff2df;
        }
        span:nth-child(2) {
          border: 0.01rem solid #fff2df;
          background: var(--theme-color);
          color: #fff2df;
        }
      }
      .phoneInfo {
        .picInfo {
          ul {
            li {
              div {
                span {
                  color: #fff2df;
                }
                i {
                  color: #fff2df;
                }
              }
            }
          }
        }
      }
      .txt_info .phoneInfo .otherInfo ul li span {
        color: #fff2df;
      }

      .info_msg {
        li {
          span {
            color: #fff2df;
          }
        }
      }
      /deep/ .el-tabs__item.is-active {
        color: #fff2df;
        font-weight: 500;
      }
      /deep/ .el-tabs__active-bar {
        background: #fff2df;
      }
      /deep/.el-tabs__item {
        color: #fff2df;
        font-weight: 400;
      }
    }
    .oneBack {
      background: rgba(154, 11, 13, 1);
      .infoMore {
        background: rgba(178, 1, 3, 1);
      }
    }

    .recommend .info_header .info_button span:nth-child(3) {
      background: rgba(178, 1, 3, 1);
      border: 0.01rem solid #fff2df;
      color: #fff2df;
    }
    .recommend .remcommend_pid li {
      background: #fff2df;
    }
    .remcommend_pid span {
      color: rgba(0, 0, 0, 0.9);
      margin-top: 0.02rem;
    }
    .twoBack {
      background: rgba(154, 11, 13, 1);
      .magazine_recommend {
        background: rgba(178, 1, 3, 1);
        margin-top: 0;
        border-top: 0.1rem solid rgba(154, 11, 13, 1);

        height: 3.38rem;
        .info_header .info_button span:nth-child(3) {
          background: #b20103 !important;
        }
        .magazine_swiper .magazine_slide {
          background: #fff2df;
        }
      }
      .media .media_msg ul {
        border-top: none;
      }
    }

    .top_out {
      padding-top: 0.14rem;
      padding-bottom: 0.2rem;
      // background: linear-gradient(to bottom, #d88385 70%, #f7f1f1 100%);
      background-image: url("../assets/home/phoneTopback.png");
      background-size: 100% 100%;

      .container {
        width: 3.45rem;
        margin: 0 auto;

        margin-bottom: 0.1rem;
        height: 1.5rem;
        border-radius: 0;
      }
      .siwoer_nav {
        background: linear-gradient(180deg, #ffe0c1 0%, #ffffff 100%);
      }
    }
  }
}
</style>
